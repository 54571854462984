import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Stay Tuned: Our HRMS App Goes Live Shortly!</h1>  
    </div>
  );
}

export default App;
